<template>
  <div class="container page">
    <van-nav-bar title="Tên thật" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-field v-model="name" :readonly="userInfo.name !== ''" label="Tên" placeholder="Vui lòng nhập tên thật của bạn" />
    </van-cell-group>
    <p>Để bảo mật tài khoản của bạn, tên thật của bạn phải trùng với tên trên thẻ ngân hàng gắn liền với nó.</p>
    <van-button class="bindCard" type="default" @click="save()">Xác nhận</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name:"",
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    save(){
      if(this.userInfo.name){
        this.$toast("Đừng lặp lại các cài đặt");
        return true;
      }
      if(this.name === "" || this.name === null || this.name === undefined){
        this.$toast.fail("Vui lòng nhập tên");
        return false;
      }
      this.$http({
        method: 'get',
        data:{name:this.name},
        url: 'user_set_name'
      }).then(res=>{
        if(res.code === 200){
          this.getUserInfo();
          this.name = this.userInfo.name;
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.bindCard {
  margin: 60px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  background-image: linear-gradient(0deg, #f4ba2e 0%, #fea036 100%);
  border-style: solid;
  border-width: 1px;
  border-color: #fea036;
  border-radius: 20px;
  -webkit-box-shadow: 0px 3px 2px 0px rgba(32, 29, 30, 0.25);
  box-shadow: 0px 3px 2px 0px rgba(32, 29, 30, 0.25);
}
</style>
